import axios from "axios";
import router from "@/router";
import store from '@/state/store';
import { getUser } from '@/helpers/authservice/user.service';

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
});

function authUserHeader() {
    // return authorization header with jwt token
    const user = getUser();

    if (user && user.token) {
        return { 'Authorization': 'Bearer ' + user.token };
    } else {
        return {};
    }
}

axiosInstance.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 401) {
            store.dispatch('authfack/cleanUser');
            router.push('/login');
        }

        return Promise.reject(error);
    }
);

export function axiosUserRequest(options) {
    const headers = authUserHeader();

    return axiosInstance({ ...options, headers });
}

export { axiosInstance as axios };
