import { axiosUserRequest } from '@/helpers/authservice/axios';
import { deepClone } from '@/helpers/utils';
import { EventBus } from '@/services/event-bus';
import store from '@/state/store.js';

export const state = {
    me: null,
};

export const getters = {
    hasMe(state) {
        return !!state.me;
    },
    getMyId(state) {
        return state.me.id;
    },
    getMe(state) {
        return state.me;
    }
}

export const mutations = {
    setMe(state, data) {
		state.me = data;
	},

    clear(state) {
        state.me = null;
    }
};

export const actions = {
    get({commit}) {
        return axiosUserRequest({ method: 'get', url: `/v1/users/me`}).then(({data}) => {
            const normalizedMe = normalize(data);

			commit('setMe', normalizedMe);
		})
    },

    clear({commit}) {
        commit('clear');
    }
};

function normalize(me) {
    const clone = deepClone(me);

    if (!clone.phone.code) {
        clone.phone.code = '1';
    }

    return clone;
}

EventBus.$on('logout', () => {
    store.dispatch('me/clear');
});
