import { axiosUserRequest } from '@/helpers/authservice/axios';
import { EventBus } from '@/services/event-bus';
import store from '@/state/store.js';

export const state = {
    user: null,
};

export const getters = {
    
}

export const mutations = {
    set(state, data) {
		state.user = data;
	},

    clear(state) {
        state.user = null;
    }
};

export const actions = {
    save({ commit }, payload) {
        const { id } = payload;

        return axiosUserRequest({ method: 'put', url: `/v1/users/${id}`, data: payload}).then(({ data }) => {
			commit('set', data);
		});
    },

    clear({commit}) {
        commit('clear');
    }
};

EventBus.$on('logout', () => {
    store.dispatch('user/clear');
});
