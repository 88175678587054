import store from '@/state/store.js';
import { parseJwt } from '@/helpers/utils';
import { EventBus } from '@/services/event-bus';
import { getUser } from '@/helpers/authservice/user.service';
import { axiosUserRequest } from '@/helpers/authservice/axios';
import router from '@/router';

export const PROFILE_TYPE_PERSONAL = 1;
export const PROFILE_TYPE_BUSINESS = 2;

export const PROFILE_TYPES = {
    [PROFILE_TYPE_PERSONAL]: 'Personal',
    [PROFILE_TYPE_BUSINESS]: 'Business',
}

export const state = {
    profiles: [],
    currentProfile: null
};

export const getters = {
    hasProfiles(state) {
        return state.profiles.length;
    },

    getProfileByType(state) {
        return type => state.profiles.find(profile => profile.type === type);
    },

    getProfileById(state) {
        return id => state.profiles.find(profile => profile.id === id);
    },

    getCurrentProfile(state) {
        return state.currentProfile;
    }
}

export const mutations = {
    set(state, data) {
		state.profiles = data;
	},

    setCurrentProfile(state, profile) {
        state.currentProfile = profile;
    },

    clear(state) {
        state.profiles = [];
        state.currentProfile = null;
    }
};

export const actions = {
    getListing({commit}) {
        return axiosUserRequest({ method: 'get', url: `/v1/profiles`}).then(({data}) => {
			commit('set', data.items);
		});
    },

    update(state, data) {
        const { id } = data;

        return axiosUserRequest({ method: 'put', url: `/v1/profiles/${id}`, data });
    },

    create(state, data) {
        return axiosUserRequest({ method: 'post', url: `/v1/profiles`, data });
    },

    uploadAvatar(context, {profileId, formData}) {
        return axiosUserRequest({ method: 'post', url: `/v1/profiles/${profileId}/photos`, data: formData });
    },

    uploadBackground(context, {profileId, formData}) {
        return axiosUserRequest({ method: 'post', url: `/v1/profiles/${profileId}/backgrounds`, data: formData });
    },

    setCurrentProfile({commit}, profile) {
        commit('setCurrentProfile', profile);
    },

    clear({commit}) {
        commit('clear');
    }
};

export async function setDefaultProfile() {
    try {
        const { token } = getUser();
        const { profile_id } = parseJwt(token);
        
        await Promise.all([
            store.dispatch('profile/getListing'),
            store.dispatch('me/get')
        ]);
        const profile = store.getters['profile/getProfileById'](profile_id);
        store.dispatch('profile/setCurrentProfile', profile);
    } catch(error) {
        router.push({name: 'Logout'})
    }
}

EventBus.$on('logout', () => {
    store.dispatch('profile/clear');
});
