import { axiosUserRequest } from '@/helpers/authservice/axios';

export const actions = {
    create(context, payload) {
        const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(payload)
		};
	
		return fetch(`${process.env.VUE_APP_API_URL}/v1/sessions`, requestOptions);
    },

    update(context, payload) {
        return axiosUserRequest({ method: 'put', url: '/v1/sessions', data: payload });
    },

    delete() {
		return axiosUserRequest({ method: 'delete', url: '/v1/sessions' });
	},
};
