import Vue from 'vue';
import router from '@/router'; 
import store from '@/state/store';
import VueCompositionAPI from '@vue/composition-api';
import { computed } from '@vue/composition-api';
import { createAcl, defineAclRules } from 'vue-simple-acl';
import { ROLE_ADMIN, ROLE_USER } from './roles';

Vue.use(VueCompositionAPI);

const user = computed(() => store.state.me.me);

const rules = () => defineAclRules(setRule => {
	setRule(ROLE_ADMIN, user => user.value.roles.includes(ROLE_ADMIN));
	setRule(ROLE_USER, user => user.value.roles.includes(ROLE_USER));
});

const simpleAcl = createAcl({
	user,
	rules,
	router,
	onDeniedRoute: '/unauthorized'
  });
  
export default simpleAcl;