import { userService, getUser, setUser } from '../../helpers/authservice/user.service';
import router from '../../router/index';
import store from '@/state/store';

let user;
try {
    user = getUser();
} catch(error) {
    setUser('');
    window.location.reload();
}
export const state = user
    ? { status: { loggeduser: true }, user }
    : { status: {}, user: null };

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return !!state.status.loggeduser
    },
}

export const actions = {
    // Logs in the user.
    // eslint-disable-next-line no-unused-vars
    login({ dispatch, commit }, { email, password }) {
        commit('loginRequest', { email });

        return userService.login(email, password)
            .then(
                user => {
                    commit('loginSuccess', user);
                    router.push({ name: 'default' }).catch(() => {});
                    return user;
                },
                error => {
                    commit('loginFailure', error);
                    dispatch('notification/error', error, { root: true });
                    return error;
                }
            );
    },
    // Logout the user
    logout({ dispatch }) {
        return store.dispatch('session/delete')
            .catch()
            .finally(() => {
                dispatch('cleanUser');
            });
    },

    cleanUser({commit}) {
        userService.logout();
        commit('logout');
    },

    // register the user
    registeruser({ dispatch, commit }, user) {
        commit('registerRequest', user);
        return userService.register(user)
            .then(
                user => {
                    commit('registerSuccess', user);
                    return user;
                },
                error => {
                    commit('registerFailure', error);
                    dispatch('notification/error', error, { root: true });
                    return Promise.reject(error);
                }
            );
    },

    update({ commit }, user) {
        commit('loginSuccess', user);
    }
};

export const mutations = {
    loginRequest(state, user) {
        state.status = { loggingIn: true };
        state.user = user;
    },
    loginSuccess(state, user) {
        state.status = { loggeduser: true };
        state.user = user;
    },
    loginFailure(state) {
        state.status = {};
        state.user = null;
    },
    logout(state) {
        state.status = {};
        state.user = null;
    },
    registerRequest(state) {
        state.status = { registering: true };
    },
    registerSuccess(state) {
        state.status = { loggeduser: true };
        state.user = user;
    },
    registerFailure(state) {
        state.status = {};
        state.user = null;
    }
};

