<script>
import appConfig from "@/app.config";
import { notificationMethods } from "@/state/helpers";
import { EventBus } from '@/services/event-bus';

export default {
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    }
  },
  mounted() {
    EventBus.$on('on-get-employee', ({employee, company}) => {
        const {id, contacts, first_name, last_name} = employee
        this.$tawkMessenger.$on('load', () => {
            this.$tawkMessenger.setAttributes({
                name : `${first_name} ${last_name}`,
                email : contacts.email,
                hash : id,
                company: company.name,
            });
        });
    });
  },
  watch: {
    /**
     * Clear the alert message on route change
     */
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      // clear alert on location change
      this.clearNotification();
    }
  },
  methods: {
    clearNotification: notificationMethods.clear
  }
};
</script>

<template>
  <div id="app">
    <RouterView />
  </div>
</template>
