import { axiosUserRequest } from '@/helpers/authservice/axios';
import { deepClone } from '@/helpers/utils';
import { EventBus } from '@/services/event-bus';
import store from '@/state/store.js';

export const state = {
    accessories: null,
};

export const getters = {
    
}

export const mutations = {
    set(state, data) {
		state.accessories = data;
	},

    clear(state) {
        state.accessories = null;
    }
};

export const actions = {
    create(context, payload) {
        const serializedData = serialize(payload);

        return axiosUserRequest({ method: 'post', url: `/v1/accessories`, data: serializedData});
    },

    getListing({ commit }) {
        return axiosUserRequest({ method: 'get', url: `/v1/accessories`}).then(({data}) => {
			commit('set', data.items.map(accessory => normalize(accessory)));
		})
    },

    update(context, payload) {
        const serializedData = serialize(payload);

        return axiosUserRequest({ method: 'put', url: `/v1/accessories/${payload.id}`, data: serializedData });
    },

    activate(context, { accessoryId, activation_code }) {
        return axiosUserRequest({ method: 'put', url: `/v1/accessories/activation/${accessoryId}`, data: { activation_code } });
    },

    clear({commit}) {
        commit('clear');
    }
};

function normalize(data) {
    const clone = deepClone(data);
    const { address } = clone;

    if (address && address.indexOf(' | ') !== -1) {
        const fullAddress = address.split('|');

        if (fullAddress.length) {
            clone.address = fullAddress[0].trim();
            clone.apt = fullAddress[1].trim();
        }
    }

    return clone;
}

function serialize(data) {
    const clone = deepClone(data);

    if (clone.apt) {
        clone.address = `${clone.address} | ${clone.apt}`;
    }

    delete clone.apt;

    return clone;
}

EventBus.$on('logout', () => {
    store.dispatch('accessories/clear');
});
