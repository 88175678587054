import store from '@/state/store';
export const userService = {
    login,
    logout,
    register,
};

export function getUser() {
    return JSON.parse(localStorage.getItem('user'));
}

function login(email, password) {
    return store.dispatch('session/create', { email, password })
        .then(handleResponse)
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                setUser(user);
            }
            return user;
        });
}

export function setUser(user) {
    localStorage.setItem('user', JSON.stringify(user));
}


function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };
    return fetch(`${process.env.VUE_APP_API_URL}/v1/users`, requestOptions)
            .then(handleResponse)
            .then(user => {
                // login successful if there's a jwt token in the response
                if (user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    setUser(user);
                }
                return user;
            });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.error && data.error.join(' ')) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
