import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueMask from 'v-mask'
import VueRouter from 'vue-router'
import vco from "v-click-outside"
import router from './router/index'
import Scrollspy from 'vue2-scrollspy';
import VueSweetalert2 from 'vue-sweetalert2';
import "@/design/index.scss";
import store from '@/state/store'
import App from './App.vue'
import i18n from './i18n'
import tinymce from 'vue-tinymce-editor'
import VueGtag from "vue-gtag";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import { enablePullToRefresh } from "webtonative";
import acl from './acl';

enablePullToRefresh(true);

if (process.env.VUE_APP_GA_MEASUREMENT_ID) {
  Vue.use(VueGtag, {
    config: { id: process.env.VUE_APP_GA_MEASUREMENT_ID }
  }, router);
}

Vue.component('tinymce', tinymce)

Vue.use(require('vue-moment'));
Vue.use(VueRouter);

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    App,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "app.tipsme.online", /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

Vue.use(vco)
Vue.use(Scrollspy);
const VueScrollTo = require('vue-scrollto')
Vue.use(VueScrollTo)
Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(Vuelidate)
Vue.use(VueMask)
Vue.use(require('vue-chartist'))
Vue.use(VueSweetalert2);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE',
    libraries: 'places',
  },
  installComponents: true
})
Vue.component('apexchart', VueApexCharts);
Vue.use(acl);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
