import { helpers } from "vuelidate/lib/validators";

export function isInt(n){
    return Number(n) === n && n % 1 === 0;
}

export function isFloat(n){
    return Number(n) === n && n % 1 !== 0;
}

export function isValidFileSize(fileSize, maxLimit) {
    return fileSize / 1024 / 1024 <= maxLimit;
}

export function priceFormat(number, options = {}) {
    if (!number && typeof number !== 'number') {
        return;
    }

    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', ...options }).format(number);
}

export function numberFormat(number, options = {}) {
    if (!number && typeof number !== 'number') {
        return;
    }

    return new Intl.NumberFormat('en-US', { style: 'decimal', ...options}).format(number)
}

export function dateFormat(date) {
    if (!date || typeof date !== 'string') {
        return '';
    }

    return new Intl.DateTimeFormat('en-US', {
        day: "numeric",
        month: "short",
        year: "numeric",
      }).format(new Date(date));
}

export function round(number) {
    return Math.round((number + Number.EPSILON) * 100) / 100;
}

export function deepClone(obj) {
    return JSON.parse(JSON.stringify(obj));
}

export function renameProperty(obj, oldName, newName) {
    if (oldName !== newName) {
        Object.defineProperty(obj, newName, Object.getOwnPropertyDescriptor(obj, oldName));
        delete obj[oldName];
    }
}
// eslint-disable-next-line
export const password = helpers.regex('password', /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$/);
// eslint-disable-next-line
export const username = helpers.regex('username', /^[a-z0-9._-]{1,30}$/);

export function formatPhone(phone) {
    if (!phone) {
        return '';
    }

    return phone.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
}

export async function downloadImage(imageSrc, name) {
    const url = new URL(imageSrc);

    url.searchParams.append('wtn-download-file', true);
    const image = await fetch(url.toString());
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);
  
    const link = document.createElement('a');
    link.href = imageURL;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export function parseJwt(token) {
    // Split the token into its three parts
    const parts = token.split('.');
    
    if (parts.length !== 3) {
      throw new Error('Invalid JWT token');
    }
  
    // Base64Url decode the payload part (second part)
    const base64Url = parts[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const payload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  
    // Parse the JSON payload
    return JSON.parse(payload);
}

export function removeUrlHash() {
    // Check if the URL has a hash
    if (window.location.hash) {
        // Use the replaceState method to remove the hash
        history.replaceState(null, null, window.location.pathname + window.location.search);
    }

}