import { axiosUserRequest } from '@/helpers/authservice/axios';
import { EventBus } from '@/services/event-bus';
import store from '@/state/store.js';

export const state = {
    business: null,
};

export const getters = {
    
}

export const mutations = {
    set(state, data) {
		state.business = data;
	},

    clear(state) {
        state.business = null;
    }
};

export const actions = {
    get({ commit }, id) {
        return axiosUserRequest({ method: 'get', url: `/v1/businesses/${id}`}).then(({data}) => {
			commit('set', data);
		})
    },

    update(context, payload) {
        return axiosUserRequest({ method: 'put', url: `/v1/businesses/${payload.get('id')}`, data: payload, headers: { 'Content-Type': 'multipart/form-data' } });
    },

    clear({commit}) {
        commit('clear');
    }
};

EventBus.$on('logout', () => {
    store.dispatch('business/clear');
});
