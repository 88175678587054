export const state = {
  layoutType: 'vertical',
  layoutHeaderTitle: null,
  layoutHeaderBadge: null,
  backLink: null,
  layoutWidth: 'fluid',
  leftSidebarType: 'dark',
  topbar: 'dark',
  loader: false
}

export const mutations = {
  CHANGE_LAYOUT(state, layoutType) {
    state.layoutType = layoutType;
  },
  CHANGE_LAYOUT_WIDTH(state, layoutWidth) {
    state.layoutWidth = layoutWidth;
  },
  CHANGE_LEFT_SIDEBAR_TYPE(state, leftSidebarType) {
    state.leftSidebarType = leftSidebarType;
  },
  CHANGE_TOPBAR(state, topbar) {
    state.topbar = topbar;
  },
  CHANGE_HEADER_TITLE(state, title) {
    state.layoutHeaderTitle = title;
  },
  CHANGE_HEADER_BADGE(state, badge) {
    state.layoutHeaderBadge = badge;
  },
  CHANGE_BACK_LINK(state, link) {
    state.backLink = link;
  },
  LOADER(state, loader) {
    state.loader = loader
  }
}

export const actions = {
  changeLayoutType({ commit }, { layoutType }) {
    commit('CHANGE_LAYOUT', layoutType);
  },

  changeLayoutHeaderTitle({ commit }, title) {
    commit('CHANGE_HEADER_TITLE', title);
  },

  changeLayoutHeaderBadge({ commit }, badge) {
    commit('CHANGE_HEADER_BADGE', badge);
  },

  changeLayoutBackLink({ commit }, link) {
    commit('CHANGE_BACK_LINK', link);
  },

  changeLayoutWidth({ commit }, { layoutWidth }) {
    commit('CHANGE_LAYOUT_WIDTH', layoutWidth)
  },

  changeLeftSidebarType({ commit }, { leftSidebarType }) {
    commit('CHANGE_LEFT_SIDEBAR_TYPE', leftSidebarType)
  },

  changeTopbar({ commit }, { topbar }) {
    commit('CHANGE_TOPBAR', topbar)
  },

  changeLoaderValue({ commit }, { loader }) {
    commit('LOADER', loader)
  },

  clearHeader({ commit }) {
    commit('CHANGE_HEADER_BADGE', null);
    commit('CHANGE_HEADER_TITLE', null);
    commit('CHANGE_BACK_LINK', null);
  }
}
