import { axiosUserRequest } from '@/helpers/authservice/axios';
import { EventBus } from '@/services/event-bus';
import store from '@/state/store.js';

export const state = {
    payouts: [],
};

export const getters = {
    
}

export const mutations = {
    set(state, data) {
		state.payouts = data;
	},

    clear(state) {
        state.payouts = [];
    }
};

export const actions = {
    getListing({ commit }) {
        return axiosUserRequest({ method: 'get', url: `/v1/payouts`}).then(({ data }) => {
			commit('set', data.items);
		});
    },

    create(state, data) {
        return axiosUserRequest({ method: 'post', url: `/v1/payouts`, data });
    },

    delete(state, data) {
        return axiosUserRequest({ method: 'delete', url: `/v1/payouts/${data.id}` });
    },

	approve(state, data) {
        return axiosUserRequest({ method: 'put', url: `/v1/payouts/${data.id}/approval` });
    },

    clear({ commit }) {
        commit('clear');
    }
};

EventBus.$on('logout', () => {
    store.dispatch('payout/clear');
});
