import store from '@/state/store';
import { setDefaultProfile } from '@/state/modules/profile';
import { ROLE_ADMIN, ROLE_USER } from '@/acl/roles';

export default [
  {
    path: '/',
    name: 'default',
    meta: {
      anyCan: [ ROLE_ADMIN, ROLE_USER ],
      authRequired: true,
      async beforeResolve(routeTo, routeFrom, next) {
        const me = store.getters['me/getMe'];

        if (me.roles.includes(ROLE_USER)) {
          next({name: 'Dashboard'});
        } else if (me.roles.includes(ROLE_ADMIN)) {
          next({name: 'Admin Dashboard'});
        }
      }
    },
    component: () => import('./views/account/dashboard'),
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: {
      can: [ ROLE_USER ],
      authRequired: true,
      async beforeResolve(routeTo, routeFrom, next) {
        await setDefaultProfile();
        next();
      },
    },
    component: () => import('./views/account/dashboard/index.vue'),
  },
  {
    path: '/settings',
    name: 'Settings',
    meta: {
      can: [ ROLE_USER ],
      authRequired: true,
      async beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        await setDefaultProfile();
        // Redirect to the home page instead
        next()
      },
    },
    component: () => import('./views/account/settings')
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: {
      can: [ ROLE_USER ],
      authRequired: true,
      async beforeResolve(routeTo, routeFrom, next) {
        await setDefaultProfile();
        next();
      },
    },
    component: () => import('./views/account/profile')
  },
  {
    path: '/accessories',
    name: 'Accessories',
    meta: {
      can: [ ROLE_USER ],
      authRequired: true,
      async beforeResolve(routeTo, routeFrom, next) {
        await store.dispatch('accessories/getListing');
        next()
      },
    },
    component: () => import('./views/account/accessories')
  },

/** Admin routes */
{
  path: '/admin/dashboard',
  name: 'Admin Dashboard',
  meta: {
    can: [ ROLE_ADMIN ],
    authRequired: true,
    async beforeResolve(routeTo, routeFrom, next) {
      next();
    },
  },
  component: () => import('./views/admin/dashboard.vue'),
},
{
  path: '/admin/accessories',
  name: 'Admin Accessories',
  meta: {
    can: [ ROLE_ADMIN ],
    authRequired: true,
    async beforeResolve(routeTo, routeFrom, next) {
      next();
    },
  },
  component: () => import('./views/admin/accessories.vue'),
},
{
  path: '/admin/transactions',
  name: 'Admin Transactions',
  meta: {
    can: [ ROLE_ADMIN ],
    authRequired: true,
    async beforeResolve(routeTo, routeFrom, next) {
      next();
    },
  },
  component: () => import('./views/admin/transactions.vue'),
},
{
  path: '/admin/payouts',
  name: 'Admin Payouts',
  meta: {
    can: [ ROLE_ADMIN ],
    authRequired: true,
    async beforeResolve(routeTo, routeFrom, next) {
      next();
    },
  },
  component: () => import('./views/admin/payouts.vue'),
},
{
  path: '/admin/businesses',
  name: 'Admin Businesses',
  meta: {
    can: [ ROLE_ADMIN ],
    authRequired: true,
    async beforeResolve(routeTo, routeFrom, next) {
      next();
    },
  },
  component: () => import('./views/admin/businesses.vue'),
},
{
  path: '/admin/users',
  name: 'Admin Users',
  meta: {
    can: [ ROLE_ADMIN ],
    authRequired: true,
    async beforeResolve(routeTo, routeFrom, next) {
      next();
    },
  },
  component: () => import('./views/admin/users.vue'),
},
{
  path: '/admin/profiles',
  name: 'Admin Profiles',
  meta: {
    can: [ ROLE_ADMIN ],
    authRequired: true,
    async beforeResolve(routeTo, routeFrom, next) {
      next();
    },
  },
  component: () => import('./views/admin/profiles.vue'),
},


/** Admin routes end */



  {
    path: '/oauth/stripe',
    name: 'Oauth Stripe',
    meta: {
      authRequired: true,
      async beforeResolve(routeTo, routeFrom, next) {
        await setDefaultProfile();
        next();
      },
    },
    component: () => import('./views/account/oauth/stripe.vue')
  },

  {
    path: '/oauth/paypal',
    name: 'Oauth PayPal',
    meta: {
      authRequired: true,
      async beforeResolve(routeTo, routeFrom, next) {
        await setDefaultProfile();
        next();
      },
    },
    component: () => import('./views/account/oauth/paypal.vue')
  },


  {
    path: '/qr-code/:id',
    name: 'QR Code',
    component: () => import('./views/account/qr'),
    meta: {
      authRequired: false
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('./views/account/login'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['authfack/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'Dashboard' })
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: '/logout',
    name: 'Logout',
    meta: {
      authRequired: true,
      beforeResolve: async function(routeTo, routeFrom, next) {
        store.dispatch('authfack/logout')
          .finally(() => {
            next({ name: 'login' })
          });
      },

    },
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('./views/account/register'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['authfack/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'default' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/email-verification',
    name: 'Email Verification',
    component: () => import('./views/account/email-verification'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        if (!routeTo.params.email) {
          next({name: 'Default'});
        } else {
          next();
        }
      },
    },
  },
  {
    path: '/forgot-password',
    name: 'Forgot password',
    component: () => import('./views/account/forgot-password'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['authfack/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'default' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/verify/:token',
    name: 'Activate',
    component: () => import('./views/account/verify'),
    meta: {
      authRequired: false,
    },
  },
  {
    path: '/reset-password/:token',
    name: 'Reset Password',
    component: () => import('./views/account/reset-password'),
    meta: {
      authRequired: false,
    },
  },
  {
    path: '/not-found',
    name: 'Not Found',
    component: () => import('./views/utility/404'),
    meta: {
      authRequired: false,
    },
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    component: () => import('./views/utility/401'),
    meta: {
      authRequired: false,
    }
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '*',
    redirect: '/not-found',
  },
]
