import { EventBus } from '@/services/event-bus';
import store from '@/state/store.js';

export const state = {
    type: null,
    message: null,
    modalMessage: null,
};

export const mutations = {
    success(state, message) {
        state.type = 'alert-success';
        state.message = message;
    },
    modalSuccess(state, message) {
        state.type = 'alert-success';
        state.modalMessage = message;
    },
    error(state, message) {
        state.type = 'alert-danger';
        state.message = message;
    },
    modalError(state, message) {
        state.type = 'alert-danger';
        state.modalMessage = message;
    },
    warning(state, message) {
        state.type = 'alert-warning';
        state.message = message;
    },
    modalWarning(state, message) {
        state.type = 'alert-warning';
        state.modalMessage = message;
    },
    clear(state) {
        state.type = null;
        state.message = null;
    },
    modalClear(state) {
        state.type = null;
        state.modalMessage = null;
    }
};

export const actions = {
    success({ commit }, message) {
        commit('success', message);
    },
    modalSuccess({ commit }, message) {
        commit('modalSuccess', message);
    },
    error({ commit }, message) {
        commit('error', message);
    },
    modalError({ commit }, message) {
        commit('modalError', message);
    },
    warning({ commit }, message) {
        commit('warning', message);
    },
    modalWarning({ commit }, message) {
        commit('modalWarning', message);
    },
    clear({ commit }) {
        commit('clear');
    },
    modalClear({ commit }) {
        commit('modalClear');
    }
};

EventBus.$on('logout', () => {
    store.dispatch('notification/clear');
});
